.orders-view {
  .root {
    width: 100%;
  }

  .header {
    border-bottom: 1px solid $gray;
    margin-bottom: 50px;
  }

  .table {
    border-collapse: collapse;
    margin: 16;
    width: 50%;
    min-width: 350px;
  }

  .table-data {
    padding: 8;
  }

  .paper {
    width: 100%;
    margin-bottom: 16px;
  }

  .text {
    &--title {
      letter-spacing: 0.2em;
      color: $gray;
      font-size: 12px;
    }
  }
}