.product-modal {

  position: relative;
  
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .heading {
    font-size: 12px;
    font-weight: 400;
  }

  .accordion {
    margin: 25px 0px;
  }

  .MuiButton-label {
    letter-spacing: 0.2em !important;
  }

  .text {
    &__product-name {
      font-size: 15px;
    }

    &--product-details {
      font-size: 20px;
    }

    &__profit {
      color: $peach;
    }
    
    &__dollar {
      font-size: 18px;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0px 0px;
  }



  @include medium-down{

    .modalLeft,.modalRight{
      width: 100%;
    }

    .product-modal__wrapper{
      flex-direction: column; 
    }

  }



}