
.MuiAppBar-colorPrimary {
    box-shadow: none !important;
    .MuiSvgIcon-root{
        margin-right: 10px;
        color: $peach;
    }

    .account-links{
        display: flex;
        justify-content: center;
        align-items: center;
       
        a{
            font-size: 12px;
            font-weight: 400;
            color: $gray;

            &:first-child{
                padding-right: 10px;
                border-right: 1px solid $gray;

            }
            &:last-child{
                padding-left: 10px;
            }
        }

    }


.makeStyles-toolbar-11{
    min-height: 50px !important;
}
}
