
.shop-card{


    &__details{
        text-align: left;
    }
    
    &__img{
        padding: 25px;
    }

}

