.product-form-card {
  .root {
    width: 100%;
    margin-top: 20px;
  }

  .back-button {
    margin-right: 8px;
  }

  .instructions {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .inventory-cell {
    border-left: 1px solid $light-gray;
  }

  .color-swatch {
    width: 50px;
    height: 50px;
  }

  .input {
    border: 1px solid $light-gray;
    width: 150px;
    padding-left: 10px;

    .MuiInputAdornment-positionStart {
      margin-right: 0px;
    }
  }

  .text {
    &__color-name {
      letter-spacing: 0.15em;
    }
  }

  label + .MuiInput-formControl {
    margin-top: 0px;
  }

  .MuiInputBase-input {
    text-align: center !important;
  }

  .MuiFormControl-fullWidth {
    width: 66%;
  }
}