

table{

    th{
        color: $gray;
        border-bottom: none;
        
    }

    .MuiTableCell-root {
        border-bottom: none;
    }

    .MuiTableCell-head {
        color: $gray;
        font-weight: 400;
        line-height: 1.5rem;
        font-size: 12px;
        letter-spacing: 2.4px;
        text-transform: uppercase;
    }

    .inventoryCell{
        position: relative;
        min-width: 150px;
        text-align: center;
        &::before{
            content: '';
            background: $light-gray;
            height:118px;
            width: 1px;
            position: absolute;
            left: 0px;
            top: 10px
        }

    }

}