
.App{

    .toast-success-text{
        color: $white;
        margin-right: 5px;
    }
    .toast-content-text{
    
        margin-right: 5px;
    }

    .toast-link{
        //border-bottom: 1px solid #FFF;
        color: #fff !important;
    }


    .Toastify__toast-container {
        position: fixed;
        width: calc(100% - 240px);
        right: 0px;
        left: initial;
        //height: 100%;
        height: auto;
        transform: translateX(0%);
        top: 0px;
        padding: 0px;
        color: $white;
        h1,h2,h3,h4,h5,h6,p{
            color: $white;
        }
    }

    .toast-error-text{
        padding-left: 20px;
    }

    
    
    .Toastify__toast-container--top-left {
    }
    .Toastify__toast-container--top-center {
    }
    .Toastify__toast-container--top-right {
    }
    .Toastify__toast-container--bottom-left {
    }
    .Toastify__toast-container--bottom-center {
    }
    .Toastify__toast-container--bottom-right {
    }

    /** Classes for the displayed toast **/
    .Toastify__toast {
    }
    .Toastify__toast--rtl {
    }
    .Toastify__toast--dark {
    }
    .Toastify__toast--default {
    }
    .Toastify__toast--info {
    }
    .Toastify__toast--success {
        background-color: $blue;
    }
    .Toastify__toast--warning {
    }
    .Toastify__toast--error {
        background-color: $peach;
        top: 35%;
        left: 25%;
        
        z-index: 9999999;
        //transform: translateY(50%) !important;
        //margin-top: 36vh;
        max-width: 400px;
        max-height: 400px;
        min-height: 300px;
        min-width: 400px;
        border: 2px dashed $white;
        text-align: center;

        background-image: url('../../images/icon-error.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 90%;


        position: fixed;
        top: calc(50vh - 200px);
        left: calc(50vw - 450px);
        

    }
    .Toastify__toast-body {
    }

    /** Classes for the close button. Better use your own closeButton **/
    .Toastify__close-button {
    }
    .Toastify__close-button--default {
    }
    .Toastify__close-button > svg {
    }
    .Toastify__close-button:hover, .Toastify__close-button:focus {
    }

    /** Classes for the progress bar **/
    .Toastify__progress-bar {
    }
    .Toastify__progress-bar--animated {
    }
    .Toastify__progress-bar--controlled {
    }
    .Toastify__progress-bar--rtl {
    }
    .Toastify__progress-bar--default {
    }
    .Toastify__progress-bar--dark {
    }
}