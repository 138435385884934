.payment_price_item, .payment_price_item.center {
    border: 1px solid #dddddd;
    position: relative;
    padding-bottom: 100px;
    width: 33%;
}

.payment_price_item:hover, .payment_price_item.center:hover {
    border: 1px solid #dddddd;
}

.payment_price_item h6 {
    font-family: 'Georgia', serif;
    font-weight: bold;
    text-transform: uppercase;
}

.payment_price_item ul {
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    font-size: 14px;
    padding-left: 10px;
}

.payment_price_item::before {
    background-image: -webkit-linear-gradient(-140deg, #246071 0, #062832 100%);
    background-image: -ms-linear-gradient(-140deg, #246071 0, #062832 100%);
}

.payment_price_item:hover {
    border: #5e5e5e;
}

.payment_price_item .payment_price_btn {
    border-radius: 2px;
}

.payment_priceing_area::before {
    background: none;
}

.payment_price_item, .payment_price_item h2, .payment_price_item h6, .payment_price_item p {
    color: #246071;
}

.payment_price_item:hover {
    color: #ffffff;
}

.payment_price_item{
        height: 325px;
        transition: opacity .4s linear;
        h2,h3,h4,h5, ul, li,p{
            color: $blue;
        }

        .MuiCardContent-root{
            padding: 40px;
        }

        &:hover{
            background-image: -ms-linear-gradient(-140deg,#246071 0,#062832 100%);
            background-image: -webkit-linear-gradient(-140deg,#246071 0,#062832 100%);
            
            h2,h3,h4,h5, ul, li,p{
                color: $white;
            }
        }

}

.payment_price_item.center {
    height: 350px;
    margin-top: -25px;
    color: #ffffff;
    background-image: -ms-linear-gradient(-140deg,#246071 0,#062832 100%);
    background-image: -webkit-linear-gradient(-140deg,#246071 0,#062832 100%);

    h2,h3,h4,h5, ul, li,p{
        color: $white;
    }

    ul li{
        color: $white;

    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
 
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
       
        opacity: 0;
        border-radius: 4px;
        -webkit-transition: opacity .4s linear;
        -o-transition: opacity .4s linear;
        transition: opacity .4s linear;
        background-image: -webkit-linear-gradient(-140deg,#246071 0,#062832 100%);
        background-image: -ms-linear-gradient(-140deg,#246071 0,#062832 100%);
        background-image: -webkit-linear-gradient(-140deg,#246071 0,#062832 100%);
            
    }
}
