
.page-header{

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $gray;

    &__filters{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 10px;

        h6{
            margin-right: 20px;
        }

        .MuiButtonBase-root{
            font-size: 12px;
            text-decoration: none;
            font-weight: 400;
            text-transform: uppercase;
            padding: 0px;
            background: none;
            border: none;
            cursor: pointer;
            border-radius: 0px !important;
            margin-right: 10px;
            
            margin-left: 10px;
            border-bottom: 1px solid transparent;

            &.active{
                border-bottom: 1px solid $gray;
            }
        }

    }

}