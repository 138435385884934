


.App .MuiButtonBase-root{
    border-radius: 5px !important;
    box-shadow: none !important;
    //min-width: 150px;
    .fas{
        margin-left: 5px;
        font-size: 14px;
        line-height: 0px;
        margin-top: 0px;
        margin-bottom: 2px;
    }    
}

.MuiButtonBase-root.Mui-disabled {
    border: none  !important;
    background: rgba($gray, 0.3) !important;
}

#root .button{
    //border-radius: 0px !important;

    &--primary{
        background: $peach;
        color: $white;
    }

    &--outline{
        border-color: 1px solid $gray;
        color: $gray !important;
    }

    &--red-outline{
        border-color: 1px solid $peach;
        background: transparent;
        color: $gray !important;
    }

    &--blue{
        background-color: $blue !important;
        color: $white !important;     
    }
    
}


.MuiButtonBase-root{
    .MuiButton-label{
        letter-spacing: 0.15em !important;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}



.MuiButton-contained.Mui-disabled {
    .MuiButton-label{
        //color: $gray;
    }
}

/*
Loading button component styles
*/
.loading-button {
    position: relative;
    &.loading{
        .loading-button__text{
            visibility: hidden;
        }
    }
    &__icon-wrapper{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}