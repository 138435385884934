.template-login {
  display: flex;
  background-image: url('../../images/onboarding_background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .paper {
    background: #fff;
    width: 40%;
  }
  
  .card {
    padding: 100px;
    display: flex;
    width: 100vh;
    //height: 40vh;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;
  }

  .welcome {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 30px;

    &--name {
      color: $medium-gray !important;
      padding-bottom: 10px;
      font-size: 24px !important;
    }
  }

  .logo {
    width: 150px;
    height: auto;
    padding: 10px;
    margin-left: 2px;
  }

  .header-text-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: fit-content;
    margin: 0 auto;
  }

  .reset-password-conatiner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-right: 150px;
  }

  .form-input{
    width: 300px;
  }

  .login-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .form {
    display: flex;
    flex-direction: column;
  }
  
  .button {
    &__blue {
      background-color: $blue;
      color: $white;
      min-width: 225px;
      &:hover{
        background-color: #284b54 !important;
      }
    }
  }
  .text__notice{
    margin: 0 auto;
    max-width: 400px;
    font-size: 12px;
  }


  .MuiButtonBase-root.Mui-disabled {
    background-color: #CCCCCC !important;
    color: $white;
  }

  .input-fields {
    justify-content: center;
    margin-top: 15px;
  }
  
  .text {
    &__welcome-message {
      font-size: 45px;
      color: $peach !important;
      padding-bottom: 40px;
    }

    &__login-message {
      color: $blue;
      font-size: 24px;
      font-weight: 800;
    }

    &__sign-up {
      font-size: 9px;
      padding-bottom: 15px;
      padding-right: 270px;
      text-align: left;
    }

    &__reset-password {
      font-size: 9px;
      padding-top: 10px;
      padding-left: 5px;
    }

    &__notice {
      text-align: left;
      max-width: 300px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .card {
    padding: 10px !important
  }

  .welcome {
    flex-direction: column !important;
    align-items: center !important;
  }
}
