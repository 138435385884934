
.supplier-card{
    position: relative;

    border: 1px solid transparent !important;
    box-shadow: none !important;

    &:hover{
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
        .action-button{
            visibility: visible !important;
        }
    }

    .product__title{
        text-align: left;
        font-size: 8px;
        margin-top: 10px;
        margin-left: -6px;
        text-transform: uppercase;
        letter-spacing: 2.4px;
        color: #999;
        top: 16px;
        left: 14px;
        z-index: 2;
        position: absolute;
    }



    .MuiCardActions-root{
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        text-align: center;


        .action-button{
            background: rgba($peach,0.8);
            color: $white;
            width: 100%;
            visibility: hidden;
            height: 50px;
            border-radius: 0px !important;
            
        }
    }


    .product-image__wrapper{

    }

    .product-image{

        transition: transform .5s ease;
        //transform-origin: -100% 50%;
        &:hover{
            transform: translate(-50%,-50%) scale(2,2);

        }
    }
}