

.checkout__breadcrumbs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    p{
        margin: 0px;
    }
    a{
        text-decoration: none;
        color: $gray;
    }
}