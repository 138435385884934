
.template-my-products{
    .root {
        width: 100%;
        border: none;
    }

    .shop-card__img{
        padding-top: 0px;
    }

    .product__image{
        position: relative;
    }

    .image {
        width: 100%;
    }

    .button {
        &__back {
            margin-right: 8px;
        }
    }

    .text {
        &__product-name {
            padding-bottom: 10px;
        }
    }

    .instructions {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .actions {
        justify-content: flex-end;
    }

    .product__image {
        width: 100%;
    }

  
}