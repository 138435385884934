
.App .template-profile-logo{

    .dropzone {
        padding: 20px;
        border: 1px dashed $light-gray;
        margin-bottom: 50px;
    }
    button {
        width: calc(100% - 15px);
    }

    .dropzone {
        height: 200px;
        color: #fff;
        background: rgba($peach, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        p{
            color: $white;
        }
    }

    .modalRight {
        position: relative;
        width: 60%;

        ul{
            padding-left: 20px;
        }
    }

    .modalLeft {
        position: relative;
        width: 40%;
         padding: 10px;
        img {
            width: 100%;
        }
    }

    .MuiButtonBase-root.MuiButton-root.Mui-disabled{
        background-color: $gray;
        color: $light-gray;
    }

}