

.App .template-profile-store{
  .root{
    width: 100%;
  }

  .store-connected-wrapper{
    padding-bottom: 15px;
    padding-left: 15px;
    text-align: left;
  }

  .header-text-container {
    text-align: left;
    padding-left: 15px;
  }

  .container {
    padding: 30px;

    &__input {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  .store-connection-string{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    .shopify-icon{
      max-width: 20px;
      margin-right: 10px;

    }

    .MuiSvgIcon-root{
      fill: $success;
      margin-left: 5px;
    }
  }


  .img {

    &__blanka {
      width: 120px;
      margin: 20px 60px;
    }

    &__shopify {
      width: 110px;
      margin: 20px 60px;
      padding-top: 12px;
    }

    &__circle-arrow {
      width: 30px;
      padding-bottom: 30px;
      margin: 20px 30px;
    }
  }

  .card{
    width: 100%;

    &__content{
        display: flex;
        width: 97.3%;
        flex-direction: column;
        text-align: center;
        align-items: center;  
        justify-content: center;       
        background-color: $blue;
        padding: 10px;
        margin: 18px;
    }

    &__content-standalone{
      width: auto;
      padding: 0px;
      margin: 0px;
    }

    &__plan-title{
        text-align: left;
        margin-bottom: 25px; 
        font-size: 23px;
    }
  }

  .text {
    &__title {
      font-size: 24px;
      padding-top: 20px;
    }

    &__title-standalone {
      font-size: 24px;
      padding-top: 20px;
      color: $blue;
      padding-bottom: 20px;
    }

    &__redirect-link {
      color: $white;
      font-size: 12px;
      padding-bottom: 15px;
    }

    &__header {
      font-size: 12px;
    
    }

    &__shopify {
      color: $white;
      padding-left: 5px;
      
    }
  }

  .MuiButton-label {
    letter-spacing: 0.2em !important;
  }

  .MuiButtonBase-root.Mui-disabled {
    background-color: #CCCCCC !important;
    color: $white;
  }

  .domain-input-name {
    background-color: $white;
    justify-content: center;
    text-decoration: none;
  }
}