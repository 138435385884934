.brand-plate{
    width: 100%;

    .table {
        border-collapse: collapse;
        margin: 16;
        width: 50%;
        min-width: 350px;
    }

    table-data {
        padding: 8;
    }

    .img {
        width: 100%;
        padding-right: 25px;
        margin: 0 auto;
        object-fit: cover;
        max-width: 350px;
        margin: auto;
        padding-top: 30;
    }

    .brand-plate__content{
        p{
            margin-bottom: 10px;
        }
    }

    .text {
        &__title {
            letter-spacing: 0.4em !important;
            margin-bottom: 10px;
        }

        &__content-title {
            letter-spacing: 0.2em !important;
            font-size: 25px;
            font-weight: 600;
            color: $peach;
        }

        &--padding {
            padding-top: 15px;
        }

        &__invalid-billing {
            font-size: 12px;
            padding-top: 10px;
        }
    }

    &__actions{
        text-align: right;
    }
}