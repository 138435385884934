
.samples-checkout{
    //background: $light-gray;
    .MuiCard-root{
        display: flex;
        flex-direction: row;
        //background: $light-gray;
    }
    .shipping-card-left{
        width: 68%;
        padding-right: 1%;
        border-right: 1px solid $light-gray;
        text-align: left;
    }

    .shipping-card-right{
        width: 30%;
        padding-left: 1%;


        table{
            border: none;
        }
    }

    .checkout-button-container{
        display: flex;
        align-items:flex-end;
        justify-content: flex-end;
        margin-top: 10px;
    }


    .checkout__actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .StripeElement {
       
        padding: 19px;
    }

    .StripeElement{
        border-color: rgba(0, 0, 0, 0.23);
      }

  
}

