.payment-method-modal {
  .modal__wrapper {
    padding: 50px;
    
  }

  .modal-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal__close {
    position: absolute;
    text-transform: uppercase;
    color: #5e5e5e;
    font-size: 8px;
    font-weight: 100;
    letter-spacing: 2.4px;
    cursor: pointer;
    top: 8px;
    right: 8px;
  }

  /* form alignment here */
  .stripe {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    width: 500px;

    .card-element-container {
      width: 100%;
    }
    .MuiTextField-root {
      width: 49%;
      margin-bottom: 15px;

      &:nth-child(2) {
        margin-left: 2%;
      }
    }

    .StripeElement {
      border-color: rgba(0, 0, 0, 0.23);
    }
  }
  .button__submit {
    width: 200px;
  }

  .form-group{
    max-width: 500px;


    .text--sub .MuiFormControlLabel-label{
      font-size: 10px;
    }

  }
  .button__downgrade{
    margin-top: 25px; 
  }

}
