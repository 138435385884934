

$peach: #d56f5b;
$white: #FFF;
$gray: #5e5e5e;
$medium-gray: #979797;
$light-gray:#e4e4e4;
$blue: #246071;
$dark-blue: #284b54;
$error: #AB0000;

$success: #00AA51;