

.MuiChip-root{
    height: 23px;
    border-radius: 8px;
    font-size: 10px;
    color: $white;

    .MuiChip-label{
        color: $white;
    }

    &.error{
        background: $error !important;
    }

}