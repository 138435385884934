

@font-face {
    font-family: 'Georgia';
    src: url('../../fonts/Georgia.ttf')  format('truetype'), /* Safari, Android, iOS */
  }

  @font-face {
    font-family: 'Georgia';
    font-weight: bold;
    src: url('../../fonts/Georgia-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
  }



h1,h2,h3,h4,h5,h6{
    font-family: "Georgia" , sans-serif;
}

h1,h2,h3{
    letter-spacing: 0.1em !important;
}

p, li{
    color: $gray;
    font-family: 'Nunito', sans-serif;
}

a{
    color: $gray !important;
    text-decoration: underline;
}

h1,h2,h3,h4,h5,h6{
    color: $peach;
}
p{
    color: $gray;
}


.text{

    &--bold{
        font-weight: bold !important;
    }

    &--nunito{
        font-family: 'Nunito Sans', sans-serif !important;
    }

    &--georgia{
        font-family: "Georgia" !important;
    }

    &--georgia-bold{
        font-family: "Georgia" !important;
        font-weight: bold !important;
    }

    &--upper{
        text-transform: uppercase;
    }

    &--center{
        text-align: center !important;
    }

    &--sub{
        font-size: 0.6rem !important;
    }

    &--left{
        text-align: left !important;
    }

    &--error{
        color: $error;
    }
    &--gray{
        color: $gray;
    }
    &--white{
        color: $white;
    }
    &--peach{
        color: $peach !important;
    }

    &--medium-gray{
        color: $medium-gray;
    }

    &--light-gray{
        color: $light-gray;
    }

    &--spacing-15 {
        letter-spacing: 0.15em !important
    }

}

