.plan-limit-modal{

    text-align: center;

    .root {
        display: flex;
        flex-direction: column;
    }

    li {
        font-family: 'Nunito Sans', sans-serif !important;
    }
    
    .modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .model-content {
        padding: 50px 50px;
    }

    .modal__plans{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .try-free-button {
        border-radius: 5px !important;
    }

    .modal-close-button{
        position: absolute;
        text-transform: uppercase;
        color: #5e5e5e;
        font-size: 8px;
        font-weight: 100;
        letter-spacing: 2.4px;
        cursor: pointer;

        top: 10px;
        right: 10px;
    }

    .payment_price_item{
     text-align: center;   
     margin-top: 25px;
     width: auto;
    }

    .text {
        &__body {
            width: 80%;
            margin: 10px auto;
            font-size: 16px;
        }
        
        &__title {
            font-family: "Georgia" , sans-serif;
            font-size: 21px;
            color: $medium-gray;
            padding-top: 40px;
        }

        &__try-free {
            font-size: 16px;
            color: $blue;
        }

        &__plan-name {
            font-size: 16px;
            letter-spacing: 0em !important;
        }
    }

    .MuiCardActions-root{
        justify-content: center;
        
        //aligns buttons on bottom
        position: absolute;
        width: 100%;
        bottom: 30px;
    }

    

    @include medium-down{

        .modal__plans{
          width: 100%;
        }
    
        .plan-limit-modal{
          flex-direction: column; 
        }
    
      }
}