



.flex{
    
    &-column{
        &-center{
            display: flex;
            flex-direction: column;   
            justify-content: center;
            align-items: center;
        }

        &-left{
            display: flex;
            flex-direction: column;   
            justify-content: center; 
            align-items: flex-start;
        }
    }

    &-row{
        &-center{
            flex-direction: row;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}


.mb{

    &-10{
        margin-bottom: 25px !important;
    }

    &-25{
        margin-bottom: 25px !important;
    }


}


.pb {
    &-10 {
        padding-bottom: 10px !important;
    }

    &-15 {
        padding-bottom: 15px !important;
    }

    &-20 {
        padding-bottom: 20px !important; 
    }

    &-25  {
        padding-bottom: 25px !important;
    }
}

.mt {

    &-15 {
        margin-top: 15px !important;
    }


    &-32 {
        margin-top: 32px !important;
    }
}