.password-reset-template {
  display: flex;
  background-image: url('../../images/onboarding_background.jpg');
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;

  .paper {
    background: $white;
    width: fit-content;
    max-width: 1000px;
    height: fit-content;
    justify-content: center;
    padding: 35px;
    border-radius: 0px;
  }

  .form-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 500px;
  }

  .message-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .text {
    &__reset-header {
      color: $blue;
      font-size: 24px;
      font-weight: 800;
      padding-bottom: 30px;
    }

    &__error {
      color: #AB0000;
      font-family: 'Nunito Sans', sans-serif !important;
      align-self: center;
    }

    &__success {
      color: $blue;
      font-family: 'Nunito Sans', sans-serif !important;
      align-self: center;
    }
  }

  .signup-container {
    display: flex;
    text-align: left;
    padding-left: 30%;
    margin-bottom: 30px;
    flex-direction: column;

    &__title{
      color: $blue;
      font-weight: 1000;
      font-size: 24px;
    }

    &__sign-in {
      font-size: 9px;
    }
  }

  .logo {
    width: 250px;
    height: auto;
    padding-bottom: 30px;
  }

  .input-fields {
    justify-content: center;
    width: 300px;
    padding-bottom: 20px;
  }

  .button {
    &__reset {
      margin-top: 10px;
      background-color: $blue;
      color: #FFF;
      width: 300px;
    }
  }
}