.order-card{

    width: 100%;
    box-shadow: none !important;

    .root {
        width: 100%;
        border: none;
    }

    &__header{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        h5{
            color: $gray;

        }
    }

    &__status{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .text {
        &--cell-details {
            letter-spacing: 2.4px;
            color: $gray;
        }

        &--header {
            color: $medium-gray;
        }
    }

    .button {
        &--back {
            margin-right: 8px;
        }
    }

    .instructions {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .actions {
        justify-content: flex-end;
    }

    .order-image {
        width: 100%;
    }
}